import React from "react";
import { Box } from "@mui/material";
import backgroundImage from "../assets/background_imgs/20231020_122621.jpg";

function LandingPage() {
  return (
    <Box
      component="section"
      sx={{
        minwidth: "100%",
        height: "450px", // Adjust the height as needed
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        position: 'relative',
        p: 0,
        m: 0,
      }}>

      {/* Dark transparent overlay */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(130, 103, 76, 0.7)", // Adjust transparency as needed
        }}
      />
      {/* Image with fancy texts */}
      <Box
        component="img"
        src= {require("../assets/background_imgs/Tagline.png")}
        alt="There is always room for bread."
        sx={{
          position: "absolute",
          maxWidth: "85%",
          maxHeight: "100%",
          objectFit: "contain",
          // Center the image
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
</Box>
  );
}

export default LandingPage;
