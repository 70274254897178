import { createTheme } from '@mui/material/styles';

const themeOptions ={
  palette: {
    mode: 'light',
    primary: {
      main: '#e38a4b',
      light: '#f0c3a3',
      dark: '#ad571a',
    },
    secondary: {
      main: '#f1df85',
      light: '#fbf7e0',
      dark: '#e6c729',
    },
    background: {
      default: '#eae4ca',
      paper: '#f9f8f0',
    },
    text: {
      secondary: '#82674c',
      primary: '#54301d',
      disabled: '#9e9386',
      hint: '#c4b6a6',
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#eae3c6',
        color: '#fff',
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
    MuiButton: {
      size: 'small',
    },
    MuiButtonGroup: {
      size: 'small',
    },
    MuiCheckbox: {
      size: 'small',
    },
    MuiFab: {
      size: 'small',
    },
    MuiFormControl: {
      margin: 'dense',
      size: 'small',
    },
    MuiFormHelperText: {
      margin: 'dense',
    },
    MuiIconButton: {
      size: 'small',
    },
    MuiInputBase: {
      margin: 'dense',
    },
    MuiInputLabel: {
      margin: 'dense',
    },
    MuiRadio: {
      size: 'small',
    },
    MuiSwitch: {
      size: 'small',
    },
    MuiTextField: {
      margin: 'dense',
      size: 'small',
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiList: {
      dense: true,
    },
    MuiMenuItem: {
      dense: true,
    },
    MuiTable: {
      size: 'small',
    },
  },
  typography: {
    fontFamily: ['Bodoni Moda', 'Montserrat'].join(','),
    h1: {
      fontFamily: 'Bodoni Moda',
    },
    subtitle1: {
      fontFamily: 'Bodoni Moda',
    },
    h2: {
      fontFamily: 'Montserrat',
    },
    h4: {
      fontFamily: 'Bodoni Moda',
    },
    h3: {
      fontFamily: 'Bodoni Moda',
    },
    h5: {
      fontFamily: 'Bodoni Moda',
    },
    h6: {
      fontFamily: 'Bodoni Moda',
    },
    subtitle2: {
      fontFamily: 'Bodoni Moda',
      fontWeight: 200,
    },
    body1: {
      fontFamily: 'Montserrat',
    },
    body2: {
      fontFamily: 'Montserrat',
    },
    button: {
      fontFamily: 'Bodoni Moda',
    },
    caption: {
      fontFamily: 'Montserrat',
    },
    fontSize: 12,
    fontWeightLight: 250,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    htmlFontSize:
			+window.getComputedStyle(document.documentElement).fontSize
				.replace("px", "")
  },
  components: {
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 46,
          height: 27,
          padding: 0,
          margin: 8,
        },
        switchBase: {
          padding: 1,
          '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + $track': {
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 24,
          height: 24,
        },
        track: {
          borderRadius: 13,
          border: '1px solid #bdbdbd',
          backgroundColor: '#fafafa',
          opacity: 1,
          transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        },

      },
    },
  },
};

// export const Theme = responsiveFontSizes(createTheme(themeOptions));
export const Theme = createTheme(themeOptions);
