import React, { useEffect, useState } from 'react';
import {Drawer} from "@mui/material";
import ProductMenu from '../components/ProductMenu'
import Layout from '../components/Layout';
import Cart from '../components/Cart';
import LatestNews from '../components/LatestNews';
import AboutUs from '../components/AboutUs';

function MainPage() {
  const loadCart = () => {
    const cart = localStorage.getItem('cart');
    if (cart) {
      return JSON.parse(cart);
    } else {
      return [];
    }
  }
  const [cartItems, setCartItems] = useState(loadCart());
  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cartItems))
  }, [cartItems])

  const addToCart = (clickedItem) => {
    setCartItems((prev) => {
      const isItemInCart = prev.find((item) => item.id === clickedItem.id);

      if (isItemInCart) {
        return prev.map((item) =>
          item.id === clickedItem.id
            ? { ...item, amount: item.amount + 1 }
            : item
        );
      }

      return [...prev, { ...clickedItem, amount: 1 }];
    });
  };

  const removeFromCart = (clickedItem) => {
    setCartItems((prev) =>
    prev.reduce((acc, item) => {
      if (item.id === clickedItem.id) {
        if (item.amount === 1) return acc;
        return [...acc, { ...item, amount: item.amount - 1 }];
      } else {
        return [...acc, item];
      }
    }, [])
  );
  };

  const openCart = () => {
    setCartOpen(true);
  };

  const getItemCount = () => {
    return sum(cartItems.map((i) => i.amount));
  };

  return (
    <div>
      <Drawer anchor="right" open={cartOpen} onClose={() => setCartOpen(false)}>
        <Cart cartItems={cartItems} addToCart={addToCart} removeFromCart={removeFromCart} />
      </Drawer>
      <Layout onCartIconClick={openCart} itemCount={getItemCount()}>
        <ProductMenu addToCart={addToCart} cart={cartItems} openCart={openCart} />
        <LatestNews/>
        <AboutUs />
      </Layout>
    </div>
  );
}

function sum(numbers) {
  return numbers.reduce(function(a, b) { return a + b; }, 0);
}

export default MainPage;
