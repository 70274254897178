import {
  Box,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ImageOne from '../assets/photos/20231012_145130.jpg';
import ImageTwo from '../assets/photos/20240601_203542.jpg';
import ImageThree from "../assets/photos/IMG_20231108_202532_603.jpg";
import ImageFour from "../assets/photos/20240527_170859.jpg";

const AboutUs = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#54301d",
      }}
    >
      <Stack
        sx={{
          height: "80%",
          width: "100%",
          margin: "auto",
          direction: "column",
        }}
      >
        <Box
          sx={{
            width: "80%",
            margin: "auto",
            marginBottom: "2rem",
            marginTop: "2rem",
            // minHeight: "500px"
          }}
        >
          <Typography variant="h5" fontWeight="600" color="#f9f8f0">
            <Divider sx={{ color: "#f9f8f0", opacity: "1.0" }}>
              ABOUT US
            </Divider>
          </Typography>

          <Grid container spacing = {4} sx={{ marginTop: "20px" }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              sx={{ alignItems: "center" }}
            >
              <Grid container>
                <Grid item xs={6}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "300px",
                      backgroundImage: `url(${ImageOne})`,
                      backgroundPosition: "50% 20%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      display: "flex",
                      position: 'relative',
                      p: 0,
                      m: 0,
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container direction="column" style={{ height: "300px" }}>
                    <Grid item xs={3}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundImage:`url(${ImageTwo})`,
                          backgroundPosition: "40% 35%",
                          backgroundSize: "cover",
                        }}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${ImageThree})`,
                          backgroundPosition: "55% 30%",
                          backgroundSize: "cover",
                        }}
                      />
                    </Grid>
                    <Grid item xs={5}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          backgroundImage: `url(${ImageFour})`,
                          backgroundPosition: "50% 70%",
                          backgroundSize: "cover",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              sx={{ alignItems: "center" }}
            >
              <Typography variant="body1" color="#f9f8f0">
                Everyone deserves fresh, handcrafted bread, not the supermarket
                kind. We embrace a no-frills approach, focusing on timeless recipes without fancy
                ingredients. Each loaf we bake is made with care, ensuring it is
                as fresh and nourishing as it is delicious when it reaches you.
                As we knead, shape, and bake, our eyes are set on a dream — a
                small, welcoming storefront that opens early, inviting you to
                start your day with the warm embrace of freshly baked bread.
                Until then, we are thrilled to offer our bread for order and
                pick-up two or three times every week at a designated public location, making
                it easier for you to incorporate the joy of fresh bread into
                your weekly routine.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default AboutUs;
