import CartItem from "./CartItem";
import { Button, Box, Divider, Grid, Typography } from "@mui/material";
import ReceiptLongSharpIcon from "@mui/icons-material/ReceiptLongSharp";
import FunctionsSharpIcon from "@mui/icons-material/FunctionsSharp";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { currencyFormat, backendUrl } from "../Config";

const Cart = ({ cartItems, addToCart, removeFromCart }) => {
  const calculateTotal = (items) =>
    items.reduce((acc, item) => acc + item.amount * item.price, 0);

  const handleCheckoutClick = () => {
      fetch(`${backendUrl}/create-checkout-session`, {
        method:'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          cart: cartItems,
          'pickup-location-time': '6 - 7.30pm at Riverview Park/Riverview Parkway, San Jose, 95134, California',
        })
      })
      .then((req) => req.json())
      .then((data) => {
        window.location.href = data.checkoutUrl;
      });
  };

  return (
    <Box
      sx={{
        m: 2,
        border: "none",
        width: "30vw", // 30% of the viewport width
        maxWidth: "500px", // max-width is 400px
      }}
    >
      <Typography variant="h6">
        <Divider>YOUR CART</Divider>
      </Typography>

      {cartItems.length === 0 ? <p>No items in cart.</p> : null}
      {cartItems.map((item) => (
        <CartItem
          key={item.id}
          item={item}
          addToCart={addToCart}
          removeFromCart={removeFromCart}
        />
      ))}

      <Grid
        container
        display="flex"
        flexDirection="row"
        sx={{
          position: "fixed",
          bottom: 10,
          justifyContent: "space-between",
          alignItems: "center",
          width: "inherit",
          // right: 3,
          zIndex: 2000,
        }}
      >
        <Grid item xs>

          <Button
            variant="outlined"
            size="small"
            startIcon={<ShoppingCartCheckoutIcon />}
            endIcon={<ReceiptLongSharpIcon />}
            onClick={handleCheckoutClick}
          >
            CHECKOUT
          </Button>

        </Grid>

        <Grid item xs>

        <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" sx={{width: "inherit"}}>
          <FunctionsSharpIcon />
          <Typography variant="h6">
            {currencyFormat(calculateTotal(cartItems))}
          </Typography>
          </Box>

        </Grid>

      </Grid>
    </Box>
  );
};

export default Cart;

// trying to fix the heading on top and checkout button at the bottom https://stackoverflow.com/questions/72078813/mui-v5-unable-to-place-button-on-the-top-right-corner
