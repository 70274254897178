import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import { currencyFormat } from "../Config";

// MUI display property - https://mui.com/system/display/

const CartItem = ({ item, addToCart, removeFromCart }) => {
  return (
    <Card
      sx={{
        display: "flex",
        width: "30vw",
        maxWidth: "500px",
        height: 100,
        marginTop: 2,
        boxShadow: "none",
        backgroundColor: "transparent",
      }}
    >
      { item.image &&
        <CardMedia
          component="img"
          sx={{ maxWidth: 100, maxHeight: 100, objectFit: "contained" }} // Set width and height for the image
          image={`data:image/png;base64, ${item.image}`}
          alt={item.name}
        />
      }
      <CardContent
        sx={{
          padding: 0,
          margin: 0,
          height: '100%',
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Grid
          container
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ height: "100%", marginLeft: "10px" }}
        >
          <Grid
            item
            xs={12}
            sx={{
              // height: "45%",
              margin: "0",
              padding: "0",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontWeight: "500", color: "text.primary" }}
            >
              {item.name}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ height: "auto", flexGrow: 1, flexDirection:"column"}}>
            {/* Empty space acting as "buffer" to make sure all the grid items are in place */}
          </Grid>

          <Grid item xs={12}>
            <Grid
              container
              sx={{
              display: "flex",
              flexdirection: "row",
              justifyContent: "space-between",
              alignItems: "center"
            }}
            >
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  flexdirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "200" }}>
                  {currencyFormat(item.price)}
                </Typography>
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  flexdirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "200" }}>
                  •
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: 0,
                  padding: 0,
                }}
              >
                <IconButton onClick={() => removeFromCart(item)}>
                  <KeyboardArrowLeftSharpIcon fontSize="small" />
                </IconButton>
                <Typography variant="body2" sx={{ fontWeight: "200" }}>
                  {item.amount}
                </Typography>
                <IconButton onClick={() => addToCart(item)}>
                  <KeyboardArrowRightSharpIcon fontSize="small" />
                </IconButton>
              </Grid>

              <Grid
                item
                xs={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "200" }}>
                  =
                </Typography>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography variant="body2" sx={{ fontWeight: "500" }}>
                  {currencyFormat(item.amount * item.price)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default CartItem;
