import React from "react";
import { AppBar, Toolbar, Badge, IconButton, Box } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import ShoppingCartSharpIcon from '@mui/icons-material/ShoppingCartSharp';
import { Link } from 'react-router-dom';
// import NextdoorIcon from './path/to/YourNextdoorIconSvg'; // Update the path accordingly
// import PrimaryLogoYellow from "./CompanyLogo";
import PrimaryLogoOrange from "./CompanyLogo";


function Header({ onCartIconClick, itemCount }) {
  return (
    <AppBar
      position="sticky"
      color="default"
      elevation={1}
      sx={{
        alignItems: "center",
        height: "100px",
        width: "100%"
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {/* Left space filler */}
        <Box sx={{ flexGrow: 1 }} />

        {/* Logo Container */}
        <Link to="/" >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            {/* <PrimaryLogoYellow /> */}
            <PrimaryLogoOrange />
          </Box>
        </Link>

        {/* Right-side Icons Container */}
        <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            color="text.primary"
            href="https://www.instagram.com/roomforbread/"
          >
            <InstagramIcon />
          </IconButton>
          { onCartIconClick &&
            <IconButton onClick = {onCartIconClick} color="text.primary" >
              <Badge badgeContent={itemCount} color="primary" sx={{"& .MuiBadge-badge": { fontFamily:"Montserrat" }}}>
                <ShoppingCartSharpIcon />
              </Badge>
            </IconButton>
          }
        </Box>
      </Toolbar>
    </AppBar>
  );
}
export default Header;
