import React from "react";
import { Box, Grid, Typography, Link, IconButton } from "@mui/material";
import logoPrimaryBeige from "../assets/logos/Primary_Beige.png";
import Pattern_Orange from "../assets/pattern/Pattern_Orange.png";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "primary.main",
        position: "static",
        bottom: 0,
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          minHeight: "120px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItem: "center",
          backgroundImage: `url(${Pattern_Orange})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Grid
          container
          spacing={1}
          sx={{ width: "80%", margin: "auto", justifyContent: "space-between" }}
        >
          <Grid item xs={12} sm={4} md={4} lg={4}>
            <Typography variant="body2" fontWeight="light">
              © 2024 Room For Bread. All rights reserved.
            </Typography>
            <Typography variant="body2">
              Website designed by {" "}
              <Link href="https://jamindesign.co.uk/" target="_blank" underline="hover" color="text.secondary">
                Jamin Design
              </Link>
            </Typography>
            {/* ... other company info ... */}
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant="body1" fontWeight="450">Need help with your orders?</Typography>
          <Typography variant="body1">
              <Link href="mailto:helpinghands@roomforbread.com" underline="hover" color="text.secondary">
                helpinghands@roomforbread.com
              </Link>
          </Typography>

          <Typography variant="body1" fontWeight="450">For other inquiries, connect via</Typography>
          <Typography variant="body1">
              <Link href="mailto:bakerybuzz@roomforbread.com" underline="hover" color="text.secondary">
                bakerybuzz@roomforbread.com
              </Link>
            </Typography>

          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4}>
          <Typography variant="body1" fontWeight="450">Social Links</Typography>
          <IconButton
            color="text.primary"
            href="https://www.instagram.com/roomforbread/"
          >
            <InstagramIcon />
          </IconButton>

          </Grid>
        </Grid>

        <Box
          component="img"
          sx={{
            height: 80,
            margin: "auto" // Adjust size accordingly
          }}
          src={logoPrimaryBeige} // Adjust the path to your logo
          alt="Room For Bread Company Logo"
        />
      </Box>
    </Box>
  );
};

export default Footer;
