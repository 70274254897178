import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import PatternBeige from "../assets/pattern/Pattern_Beige.png";
import { deliveryDay, deliveryTime } from "../Config";

const LatestNews = () => {
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: "20px",
        // marginBottom: "20px",
        backgroundColor: "#f1df85",
        // height:"150%"
      }}
    >
      <Stack
        sx={{
          height: "auto",
          width: "100%",
          margin: "auto",
          backgroundImage: `url(${PatternBeige})`,
          backgroundSize: "cover",
          direction: "column",
        }}
      >
        <Box sx={{ width: "80%", margin: "auto", marginBottom: "2rem", marginTop:"2rem"}}>
          <Typography variant="h5" fontWeight="600">
            <Divider>LATEST NEWS</Divider>
          </Typography>
        </Box>

        <Box sx={{ width: "80%", margin: "auto", marginBottom: "2rem" }}>
          <Typography variant="body1" fontWeight="500">
            We are starting small - limited collection of baked goods and small
            batches - but with big dreams. Our goal is to grow into a cozy
            bakery nestled within your neighborhood, where fresh bread is just a
            short walk away.
          </Typography>
        </Box>

        <Stack
          direction="row"
          spacing={4}
          sx={{ width: "80%", margin: "auto", marginBottom: "2rem" }}
        >
          <Box sx={{ width: "55%", marginBottom: "2rem" }}>
            <Typography variant="body1" fontWeight="600">
              Pick-Up Details:
            </Typography>
            <Typography variant="body1">
              On <strong>{ deliveryDay }</strong>, <strong> {deliveryTime} </strong> at
              <strong> Riverview Park </strong>
              (San Jose, California, 95134) by the basketball and tennis fields.
              Just look for our white compact van (Nissaan NV200; license plate
              53883X3)!
            </Typography>
            <Typography variant="body1" fontWeight="600">
              Heads up:
            </Typography>
            <Typography variant="body1">
              Orders might close sooner due to our one-person operation.
            </Typography>
          </Box>
          <Box sx={{ width: "45%", marginBottom: "2rem" }}>
            <Typography variant="body1" fontWeight="600">
              It Is Just Me For Now:
            </Typography>

            <Typography variant="body1">
              As the baker, delivery driver, and everything in between, I am
              juggling it all. Currently, I am unable to fulfill special
              requests for different pickup times or menu items. I would love to
              hear your thoughts, whether it is feedback, suggestions for
              pick-up locations and times, or some baked goods you would love to
              find on our menu.
            </Typography>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LatestNews;
