import React, { useState, useEffect } from "react";
import { Button, Container, Divider, Grid, Typography } from "@mui/material";
import ProductCard from "./ProductCard"; // Adjust the import path as necessary
import { backendUrl } from "../Config";
import PickupInfo from "./PickupInfo";

const ProductMenu = ({ addToCart, cart, openCart }) => {
  const [products, setProducts] = useState(null);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch(`${backendUrl}/products`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((products) => products.toSorted((p) => (- p.qty_available)))
      .then(setProducts);
  }, []);

  const qtyInCart = (product) => {
    const productInCart = cart.find((e) => e.id === product.id);
    if (productInCart) {
      return productInCart.amount;
    } else {
      return 0;
    }
  }

  return (
    <Container disablegutters="true" sx={{ marginTop: "35px"}}>
      <Typography variant="h5" fontWeight="600">
        <Divider>OUR SHOP</Divider>
      </Typography>
      <PickupInfo />
      <Grid container sx={{ marginTop: "20px" }}>
        {
          products === null
          ? "Loading menu..."
          : products.map((product) => (
            <Grid
              item
              key={product.id}
              xs={12}
              sm={6}
              md={4}
              lg={4}
              sx={{ alignItems: "center" }}
            >
              <ProductCard
                product={product}
                onAddToCart={() => addToCart(product)}
                qtyInCart={qtyInCart(product)}
              />
            </Grid>
          ))
        }
      </Grid>
      <div style={{
        display: 'flex'
      }}>
        <Button onClick={openCart} sx={{
            margin: '10px auto',
            border: 'solid 1px',
            borderRadius: '10px',
          }}>
            Checkout
        </Button>
      </div>
    </Container>
  );
};

export default ProductMenu;
