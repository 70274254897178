import React from "react";
import {
  Box,
  Card,
  CardMedia,
  Grid,
  Typography,
  IconButton,
  Button,
  Badge,
  Stack,
} from "@mui/material";
import PlusOneSharpIcon from '@mui/icons-material/PlusOneSharp';
import { currencyFormat } from "../Config";

const ProductCard = ({ product, onAddToCart, qtyInCart }) => {
  // Function to dynamically import allergen icons based on the product's allergens attribute
  const getAllergenIcons = (allergens) => {
    return allergens.map((allergen) => {
      const iconPath = `./food_allergen_icons/${allergen.toLowerCase()}.png`;
      return (
        <div key={allergen} style={{ position: 'relative' }}>
          <img
            src={iconPath}
            alt={allergen}
            style={{ height: "50px", marginRight: "4px", zIndex: 20 }}
          />

          {/*
          <div style={{
            backgroundColor: 'white',
            marginTop: -16,
            marginBottom: 3,
            marginLeft: 3,
            height: 6,
            width: '80%',
            zIndex: 10,
            display: 'block',
            borderRadius: 5,
          }}>
          </div>
          */}


        </div>
      );
    });
  };

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "row",
        height: 200,
        margin: 1,
        backgroundColor: "transparent",
        boxShadow: "none",
        border: 0,
      }}
    >
      <Grid
        container
        disablegutters="true"
        sx={{ width: "100%", height: "100%" }}
      >
        <Grid item xs={5} style={{position: 'relative'}}>
          <CardMedia
            component="img"
            sx={{ width: "100%", height: 200, objectFit: "cover" }}
            image={ product.image ? `data:image/png;base64, ${product.image}` : 'coming_soon.jpg'}
            alt={product.name}
          />
          {/* Allergen Icons */}
          <div style={{ position: 'absolute', bottom: 0, left: 0, padding: 0, display: 'flex' }}>
            {getAllergenIcons(product.allergens)}
          </div>
        </Grid>

        <Grid item xs={7} sx={{ paddingLeft: "10px" }}>
          <Stack
            direction="column"
            justifyContent="space-between" // Correctly distribute space
            alignItems="flex-start" // Adjust alignment as needed
            sx={{ height: "100%" }} // Ensure Stack takes full height of its container
            spacing={0.5}
          >
            <Box sx={{ height: "30%" }}>
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "bold", color: "primary.main" }}
              >
                {product.name}
              </Typography>
            </Box>
            <Box sx={{ height: "12%" }}>
              <Typography
                variant="body2"
                sx={{ fontWeight: "regular", color: "text.primary" }}
              >
                {currencyFormat(product.price)}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <Typography
                variant="body2"
                sx={{
                  fontWeight: "200",
                  fontSize: "0.8rem",
                  color: "text.secondary",
                  flex: 1, // Fill the remaining space
                  overflow: "hidden", // In case the text is too long
                  textOverflow: "ellipsis", // Add an ellipsis to truncated text
                  display: "-webkit-box",
                  WebkitLineClamp: 3, // Limit to 3 lines
                  WebkitBoxOrient: "vertical",
                }}
              >
                {product.description}
              </Typography>
            </Box>
            {/* This Box acts as a flexible spacer */}
            <Box margin = '0' >
                <IconButton sx={{
                  padding: 0,
                }}>
                  <Badge badgeContent={qtyInCart} color="primary" sx={{"& .MuiBadge-badge": { fontFamily:"Montserrat" }}}>
                    { product.qty_available - qtyInCart > 0 ?
                      <Button
                        variant="outlined"
                        size="small"
                        endIcon={<PlusOneSharpIcon fontSize='small' />}
                        onClick={onAddToCart}
                        sx={{ alignSelf: "flex-end" }} // Align button to the end if needed
                      >
                        ADD TO CART
                      </Button> :
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ alignSelf: "flex-end" }} // Align button to the end if needed
                        disabled={true}
                      >
                        OUT OF STOCK
                      </Button>
                    }
                  </Badge>
                </IconButton>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ProductCard;
