import React from 'react';
import logoPrimaryOrange from '../assets/logos/Primary_Orange.png';
// import logoPrimaryBeige from '../assets/logos/Primary_Beige.png';

// const PrimaryLogoYellow = () => (
//   <img src={logoPrimaryYellow} alt="Company Logo" style={{ width: '100px', height: 'auto' }} />
// );

// export default PrimaryLogoYellow;




const PrimaryLogoOrange = () => (
  <img src={logoPrimaryOrange} alt="Company Logo" style={{ width: '60px', height: 'auto' }} />
);

export default PrimaryLogoOrange;


