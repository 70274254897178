import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MainPage from './pages/MainPage';
import CheckoutConfirmationPage from './pages/CheckoutConfirmationPage';


function App() {
  const router = createBrowserRouter([
    { path: "/", element: <MainPage /> },
    { path: "/checkout-confirmed", element: <CheckoutConfirmationPage /> },
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
