import React from 'react';
import { Container } from '@mui/material';
import Header from './Header';
import LandingPage from '../components/LandingPage';
import Footer from './Footer';


function Layout({ children, onCartIconClick, itemCount }) {
  return (
    <>
      <Header onCartIconClick={onCartIconClick} itemCount = {itemCount} />
      <LandingPage />
      <Container disableGutters  sx={{
        m:0,
        p:0,
        minWidth: "100%",
        alignItems: "center"
        }}>
        {children}
      </Container>
      <Footer />
    </>
  );
}

export default Layout;
