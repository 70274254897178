import React from 'react';
import { Container, Divider, Typography, Box, Stack } from "@mui/material";
import { Link } from 'react-router-dom';
import Layout from '../components/Layout';
import PickupInfo from "../components/PickupInfo";
import PatternBeige from "../assets/pattern/Pattern_Beige.png";
import { deliveryDay, deliveryTime } from '../Config';


function CheckoutConfirmationPage() {
  localStorage.clear();
  return (
    <div>
      <Layout>
        <Container disablegutters="true" sx={{ marginTop: "35px"}}>
          <PickupInfo />
          <Box
            sx={{
              width: "100%",
              marginTop: "20px",
              // marginBottom: "20px",
              backgroundColor: "#f1df85",
              // height:"150%"
            }}
          >
            <Stack
              sx={{
                height: "auto",
                width: "100%",
                margin: "20px auto",
                backgroundImage: `url(${PatternBeige})`,
                backgroundSize: "cover",
                direction: "column",
              }}
            >
              <Box sx={{ width: "80%", margin: "auto", marginBottom: "2rem", marginTop:"2rem"}}>
                <Typography variant="h5" fontWeight="600">
                  <Divider>CHECKOUT CONFIRMED</Divider>
                </Typography>
              </Box>
              <Stack
                direction="row"
                spacing={4}
                sx={{ width: "80%", margin: "auto", marginBottom: "2rem" }}
              >
                <Box sx={{ width: "55%", marginBottom: "2rem" }}>
                  <Typography variant="body1">
                    See you soon. You can text us at (669) 777-3307.
                  </Typography>
                  <Typography variant="body1">
                    Click <Link to="/">here</Link> to go back to ordering.
                  </Typography>
                </Box>
                <Box sx={{ width: "45%", marginBottom: "2rem" }}>
                  <Typography variant="body1" fontWeight="600">
                    Pick-Up Details:
                  </Typography>
                  <Typography variant="body1">
                    On <strong>{ deliveryDay }</strong>, <strong> {deliveryTime} </strong> at
                    <strong> Riverview Park </strong>
                    (San Jose, California, 95134) by the basketball and tennis fields.
                    Just look for our white compact van (Nissaan NV200; license plate
                    53883X3)!
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Container>
      </Layout>
    </div>
  );
}

export default CheckoutConfirmationPage;
