import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import PatternOrange from "../assets/pattern/Pattern_Orange.png";
import { deliveryDay, deliveryTime } from "../Config";

var rowIDCounter = 0;
function createData(pickup_day, pickup_time, pickuo_location) {
  rowIDCounter++;
  return { id: rowIDCounter, pickup_day, pickup_time, pickuo_location };
}

const rows = [
  createData(
    deliveryDay,
    deliveryTime,
    "Riverview Park, San Jose, California, 95134"
  ),
];

function PickupInfo() {
  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        marginTop: "30px",
        backgroundColor: "#e38a4b",
      }}
    >
      <TableContainer
        component="section"
        display="flex"
        sx={{
          width: "100%",
          margin: "auto",
          backgroundImage: `url(${PatternOrange})`,
          backgroundSize: "cover",
          backgroundPosition: "30% 65%",
        }}
      >
        <Table
          sx={{ width: "60%", minWidth: 450, margin: "auto" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">Pick-up&nbsp;Day</TableCell>
              <TableCell align="center">Pick-up&nbsp;Time</TableCell>
              <TableCell align="center">Pick-up&nbsp;Location</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="center">
                  <Typography variant="subtitle1"  sx={{ fontWeight: "650", color:'#eae4ca' }}>{row.pickup_day} </Typography>
                </TableCell>
                <TableCell align="center"><Typography variant="subtitle1"  sx={{ fontWeight: "650", color: '#eae4ca'}}>{row.pickup_time}</Typography></TableCell>
                <TableCell align="center"><Typography variant="subtitle1"  sx={{ fontWeight: "650", color: '#eae4ca'}}>{row.pickuo_location}</Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default PickupInfo;
